.about-desktop-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 7vh;
}

.design-lines-desktop {
  mix-blend-mode: color-dodge;
  z-index: -1;
}

.design-lines-desktop-reverse {
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  width: 400px;
  max-width: 33dvw;
}

.design-lines-desktop-non-reverse {
  width: 400px;
  position: absolute;
  right: 0;
  max-width: 33dvw;
}

.about-img-container {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
we have:
- 1st layer -> blue rectangle 
- 2nd layer -> photo
*/
.overlap-group-img-desktop {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: max(10%, 160px);
}

.photo-img-desktop {
  width: 40dvw;
  height: 30dvw;
  position: absolute;
  filter: brightness(0.3);
  opacity: .6;
  z-index: -1;
  top: -4dvw;
  left: 20px;
  transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
}

.rectangle-background-desktop {
  opacity: 0.7;
  background-color: var(--alt1);
  z-index: -2;
  flex-shrink: 0;
}

.about-text-desktop {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 7%;
}

.title-text-desktop {
  margin-top: max(15%, 90px);
  margin-bottom: 7%;
  font-size: 44px;
  font-weight: 700;
  border: 1px 0px 0px 0px;
  text-align: left;
  background: linear-gradient(90deg, var(--white) 0%, var(--alt2) 100%);
  color: transparent;
  background-clip: text;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.vertical-lines-img {
  height: 33dvw;
  max-width: 7dvw;
  mix-blend-mode: color-dodge;
}

.vertical-lines-desktop {
  position: absolute;
  top: 10vh;
  left: 0;
  z-index: -1;
}

.horizontal-lines-img {
  width: 400px;
  max-width: fit-content;
  mix-blend-mode: color-dodge;
}

.horizontal-lines-desktop {
  position: absolute;
  top: 5vh;
  left: 0;
  z-index: -1;
}
