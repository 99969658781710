.title-partners{
    text-align: center;
    font-size: 8dvw;
    background: -webkit-linear-gradient(30deg, var(--contrast) 41%, var(--white) 59%);
    text-shadow: 4px 4px 0px rgba(255, 255, 255, 0.2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    margin-top: 5dvw;
}

.read-more-modal {
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.read-button-wrapper {
    width: 90dvw;
}

.read-back-icon {
    height: 70%;
}

.content-wrapper {
    height: 70dvh;
    background-color: var(--main);
    border: 2px solid var(--contrast);
    padding: 15px 30px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.read-more {
    position:absolute;
    left: 50px;
    bottom: 7%;
    background: var(--contrast);
    border: none;
    color: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--white);
    font-family: Montserrat;
    font-size: 1.3dvw;
    font-weight: 700;
}

.read-more:hover {
    cursor: pointer
}

.title-wrapper-mobile {
    overflow-x: hidden;
}

.design-line{
    position: absolute;
    mix-blend-mode: color-dodge;
    width: 28dvw;
}
.left, .left-mobile{
    top:0px;
    left: 0px;
}

.right-mobile {
    top: 23vw;
    right: 0px;
}

.left-mobile {
    top: 50px
}

.right{
    right:1%;
    top: 13dvw;
}
.flex{
    display:flex;
    position: relative;
    z-index: 10
}
.sponsors{
    position: relative;
    display:flex;
    font-size:48px;
    height: fit-content;
}
.sponsors img.start{
    position:relative;
    z-index: 1;
    left:-13%;
    width: 30%;
}

.second-image { 
    margin-top: 30dvw;
}

.end, .end-mobile {
    right: -13dvw;
    width: 30%;
    position: absolute;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
}

.end-mobile {
    right: -15vw;
}

.sponsor, .left-sponsor, .left-sponsor-mobile{
    align-self: center;
    position: relative;
    left:-18%;
    z-index: 2;
    font-size: 6dvw;
}

.left-sponsor-mobile {
    font-size: 8dvw;
}

.right-text, .right-text-mobile{
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: rgba(11, 13, 61, 0.20);
    font-family: Montserrat;
    font-size: 6dvw;
    font-weight: 700;
    position: absolute;
    right: 9dvw;
    top: 10dvw;
}

.right-text-mobile {
    font-size: 8dvw;
    right: 10dvw;
}

.center, .center-mobile{
    font-size: 36px;
    margin:auto;
    text-wrap: nowrap;
}

.center-mobile {
    font-size: 16px;
}

.partner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 6vw;
    row-gap: 20px;
}

.partner-text {
    font-size: 1.8dvw;
    margin-left: 20px;
    text-align: left;
}
  
.partner-text-mobile {
    font-size: 1.7dvw;
    margin-left: 20px   
}

.partner > div > span > a {
    margin-right: 20px;
    margin-left: 20px;
}
  
.partner h2 {
    text-align: right;
    font-size: 28px;
    margin-bottom: 20px;
}
  
.partner-img {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
}
  
.partner-img:hover {
    opacity: 1;
}
.partners-div {
    overflow: hidden;
}
  
.partners-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-wrap: wrap;
    width: 100%;
}
  
.partners-wrapper > div {
    width: 100%;
}
  
.first-row,
.second-row,
.third-row {
    width: 50%;
}
  
.third-row {
    margin-bottom: 10px;
}
  
.fourth-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
}
  
  /* SUBTITLE */
  
.partners-div > h2 {
    font-size: 36px;
    text-align: right;
    margin-right: 14vw;
    margin-bottom: 0;
}
  
.partners-wrapper h2 {
    font-size: 30px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 10px;
}
  
.fourth-row h2 {
    text-align: center;
}
  
  /* GREEN LINE */
  
.partners-wrapper hr {
    height: 8px;
    width: 250px;
}
  
.partners-wrapper > div hr {
    margin-bottom: 50px;
}
  
.partners-wrapper > div:nth-child(1) hr {
    margin-bottom: 30px;
}
  
.partners-div hr {
    height: 1px;

    border: none;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}
  
.partners-div > hr {
    height: 4px;
    background: #1a936f;
    width: 30vw;
    min-width: 400px;
    margin-right: 0;
    border-radius: 12px 0px 0px 12px;
}
  
.fourth-row hr {
    text-align: center;
    width: 80%;
    min-width: 160px;
    margin: auto;
    border-radius: 12px 12px 12px 12px;
}
  
.starter-div,
.logistic-div {
    position: relative;
}
  
.starter-div:after,
.logistic-div:after {
    content: "";
    height: 150px;
    width: 1px;
    position: absolute;
    right: -20px;
    top: 20px;
}
  
  /* LOGO SIZES */
  
.first-row img {
    /* height: 64px; */
    max-height: 100px; 
    min-width:64px;
    object-fit: contain;
}

.second-row img {
    height: 64px;
}

.third-row img {
    height: 44px;
}

.fourth-row img {
    max-height: 64px;
    max-width: 130px;
    object-fit: contain;
}

.academic-div img {
    /* margin-top: -20px; */
    height: 60px;
}

.starter-div .partner span:nth-child(2) img {
    /* margin-top: -20px; */
    height: 54px;
}
  
  
@media screen and (max-width: 1356px) {
    .partners-wrapper h2 {
        margin-top: 20px;
    }
}
  
@media screen and (max-width: 1204px) {
    .first-row img {
        height: 90px;
    }
    .second-row img {
        height: 50px;
    }
    .third-row img {
        height: 40px;
    }
    .fourth-row img {
        height: 30px;
    }
    .academic-div img {
        height: 56px;
    }
}

@media screen and (max-width: 1102px) {
    .first-row img {
        height: 80px;
    }
    .second-row img {
        height: 44px;
    }
    .third-row img { 
        height: 54px;
    }
    .fourth-row img {
        height: 54px;
    }
    .academic-div img {
        height: 58px;
    }
}
  
@media screen and (max-width: 975px) {
    .third-row img {
        height: 54;
    }
}

  /* LINII VERTICALE */
@media screen and (max-width: 1653px) {
    .logistic-div:after {
        display: none;
    }

    .academic-div hr {
        width: 250px;
    }
}
  
  
@media screen and (max-width: 1388px) {
    .starter-div:after {
        display: none;
    }
    .logistic-div:after {
        display: block;
    }
    .starter-div hr {
        width: 250px;
    }
}
  
  
  
@media screen and (max-width: 1331px) {
    .logistic-div:after {
        display: none;
    }
    .logistic-div hr {
        width: 250px;
    }
}
  
  /* Mobil */
  
@media screen and (max-width: 958px) {
    .first-row img {
        height: 84px;
    }
    .second-row img {
        height: 80px;
    }
    .third-row img {
        height: 64px;
    }
    .fourth-row img {
        height: 64px;
    }
    .academic-div img {
        height: 58px;
    }

    .fourth-row {
        flex-direction: column;
    }

    .partners-div > hr {
        width: 40vw;
        min-width: 200px;
    }

    .partners-div > h2 {
        font-size: 28px;
    }

    .partners-wrapper h2 {
        font-size: 24px;
    }

    .partners-wrapper hr {
        width: 200px;
    }
}
  
@media screen and (max-width: 958px) {
    .starter-div:after,
    .logistic-div:after {
        display: block;
    }
}
  
@media screen and (max-width: 670px) {
    .first-row img {
        height: 84px;
    }
    .second-row img {
        height: 60px;
    }
    .third-row img {
        height: 54px;
    }
    .fourth-row img {
        height: 54px;
    }
    .academic-div img {
        height: 58px;
    }
}
  
@media screen and (max-width: 532px) {
    .first-row img {
        height: 90px;
    }
    .second-row img {
        height: 50px;
    }
    .third-row img {
        height: 54px;
    }
    .fourth-row img {
        height: 54px;
    }
    .academic-div img {
        height: 58px;
    }
}
/* frate nu stiu ce sunt breakpointsurile de sus, ma sperie si pe mine */
@media screen and (max-width: 768px) {
    .right{
        top:5%;
    }
    .sponsors img.end{
        right:-50%;
    }
    .sponsor{
        font-size: 28px;
    }
    .sponsor.right{
        right:-53%;
    }
    .center{
        font-size: 24px;
    }

    .rules-wrapper {
        width: 70vw;
    }

    .close-modal-button-content {
        margin-top: 90px;
        background-color: var(--main);
        border: 2px solid var(--contrast);
        height: 40px;
        width: 40px;
        margin-left: 2.3vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .read-more {
        position:absolute;
        left: 30px;
        bottom: 10%;
        background: var(--contrast);
        border: none;
        color: var(--white);
        padding: 5px 8px;
        border-radius: 3px;
        font-family: Montserrat;
        font-size: 1.5dvw;
        font-weight: 700;
    }

}
/* @media screen and (max-width: 1200px) {
    .sponsors img.end{
        right:-50%;
    }
    .sponsor.right{
        right:-45%;
    }
} */