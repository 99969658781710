.partners-list, .partners-list-mobile {
    width: 100dvw;
    height: fit-content;
    position: relative;
    margin-bottom: 10px;
    margin-top: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
}

.partners-list-mobile {
    background: linear-gradient(90deg, #0B0D3D 0%, #FEFEFA 12%, #FEFEFA 88%, #0B0D3D 100%);

}

.partners {
    width: 100dvw;
    margin-top: 2.5dvw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.text-partners {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 5.3dvw;
    font-weight: 700;
    margin-bottom: 10px;
}

.challenge-text, .sponsors-text, .anual-text, .barter-text {
    font-size: 3dvw;
}

.media-text {
    margin-top: 10px;
    font-size: 2.5dvw;
}

.mobile-subtext {
    font-size: 4.5dvw
}

.main-wrapper, .challenge-wrapper, .anual-wrapper, .barter-wrapper, .sponsors-wrapper, .media-wrapper {
    display: flex;
    width: 100dvw;
    justify-content: space-around;
    align-items: center;
}

.mobile-wrapper {
    margin-top: 15px;
    flex-wrap: wrap;
    width: 80%;
    gap: 5px
}



.partner-image {
    height: 16dvw;
    max-width: 30dvw;
    z-index: 10;
}

.partner-image-mobile {
    height: 20dvw;
    z-index: 9;
}

.small-partners {
    height: 7dvw;
    max-width: 19dvw;
}

.smallest-partners {
    height: 5dvw;
    max-width: 100dvw;
}

.small-partners-mobile {
    height: 10dvw;
}

.smallest-partners-mobile {
    height: 8dvw;
}

.two-categories-wrapper {
    display: flex;
}

.deg-180 {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}


.small-design-line-top, .small-design-line-top-mobile {
    mix-blend-mode: color-dodge;
    position: absolute;
    width: 24dvw;
    top: -12dvw;
    left: -9dvw;
}

.small-design-line-top-mobile {
    width: 34dvw;
    top: -5dvw;
    left: -16dvw;
    z-index: 10;
}

.big-design-line-top, .big-design-line-top-mobile {
    position: absolute;
    mix-blend-mode: color-dodge;
    width: 50dvw;
    right: -15dvw;
    top: -15dvw;
}

.big-design-line-top-mobile {
    width: 80dvw;
    right: -25dvw;
    top: -15dvw;
}

.big-design-line-bottom, .big-design-line-bottom-mobile {
    position: absolute;
    mix-blend-mode: color-dodge;
    width: 50dvw;
    left: -15dvw;
    bottom: -15dvw;
}

.big-design-line-bottom-mobile {
    width: 80dvw;
    left: -25dvw;
    bottom: -15dvw;
}

.small-design-line-bottom, .small-design-line-bottom-mobile {
    mix-blend-mode: color-dodge;
    position: absolute;
    width: 24dvw;
    bottom: -15dvw;
    right: -7dvw;
    z-index: -1;
}

.small-design-line-bottom-mobile {
    width: 34dvw;
    bottom: -8dvw;
    right: -16dvw;
    z-index: 10;
}

.blue-text {
    color: var(--main);
    z-index: 100;
}

.mobile-line {
    z-index: 1;
}

.sponsor-image {
    max-width: 100dvw;
}