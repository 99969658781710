.faq-card {
    background: rgba(11, 13, 61, 0.41);
    display: flex;
    flex-direction: column;
    padding: 11px 0px;
    flex-shrink: 0;
}

.top-card-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 0px 18px 10px 31px;
}

.top-card-icon-wrapper {
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--alt2);
}

.top-card-title {
    color: var(--white);
    font-family: Montserrat;
    font-weight: 700;
    display:flex;
    align-items:center;
    justify-content: center;
    margin: auto 0;
}

.top-card-icon {
    height: 21px;
    width: 21px;
    filter: invert(12%) sepia(46%) saturate(3438%) hue-rotate(204deg) brightness(94%) contrast(100%);
}

.bottom-card-wrapper {
    display: flex;
    justify-content: start;
    margin-left: 18px;
    margin-right: 18px;
}

.bottom-card-description {
    color: var(--white);
    font-family: Montserrat;
    font-weight: 400;
}