.team-top {
    position: relative;
    width: 100%;
    /* height:10px !important; */
}

.team-header {
    display: flex;
    justify-content: center;
    margin: 0px;
    margin-top: 50px;
    margin-bottom: 200px;
    @media screen and (max-width: 768px)  {
        margin-bottom: 50px;
    }
}

.team-people-container {
    margin-top: 120px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px)  {
        flex-direction: column;
        margin-top: 30px;
    }
}

.team-unnecesary-deco-left {
    width: 26vw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(90deg);
    top: -12vw;
    left: -1vw;
    z-index: -1;
    @media screen and (max-width: 768px)  {
        display: none;
    }
}

.full-width {
    width: 90dvw
}

.team-unnecesary-deco-right {
    width: 26vw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(-90deg);
    top: -8vw;
    right: -1vw;
    z-index: -1;
    @media screen and (max-width: 768px)  {
        display: none;
    }
}

.team-people-container-deco {
    position: absolute;
    width: 120%;
    /* height: 120%; */
    top: 0px;
    z-index: -1;
    @media screen and (max-width: 768px)  {
        display: none;
    }
}

.margin-top-deco {
    /* margin-top: 100px; */
}

.team-deco-img {
    width: 118vw;
    height: 636px;
}