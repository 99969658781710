.orange-gradient-text {
    width: fit-content;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, var(--contrast) 0%, var(--soft-contrast) 50%, var(--white) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    text-wrap: nowrap;
}

.orange-gradient-text-reverse {
    width: fit-content;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #FEFEFA 0%, #F08456 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.text-wrapper {
    position: relative;
}

.text-background {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: -1;
    opacity: .6;
}