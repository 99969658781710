
.game-background {
    position:absolute;
    top:0px;
    height:100%;
    z-index:-1;
    filter:brightness(75%) sepia(10) saturate(30) hue-rotate(200deg);
    animation:headShake 13s ease-in-out infinite;
    /* transform:scaleX(1.05); */
}

.game-page {
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom:50px;
    margin-top:20px;
}

.game-container {
    width:25%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.game-header {
    display:flex;
    justify-content: space-between;
    width:100%;
    height:fit-content;
    min-height:20px;
    color:var(--contrast);
    font-weight:bold;
    font-size:20px;
    text-align: center;
}

.game-header > p {
    margin:0px;
}

.game-box {
    margin: 10px 0px;
    background-color: var(--alt1);
    width:100%;
    height:60vh;
    border: 4px solid var(--alt2);
    padding:10px 0px;
    overflow-y:scroll;
    scrollbar-width:none;
}

.game-box::-webkit-scrollbar {
    display:none;
}

.game-input {
    width:98%;
    background-color: var(--alt2);
    border:none;
    font-size:16px;
    padding:4px;
}

.answer-row {
    display:flex;
    justify-content: space-between;
    animation: backInDown .5s ease;
}

.answer-row > span {
    padding: 8px 28px;
}

.game-input-container {
    position:relative;
    width:100%;
}

.game-input-submit {
    position:absolute;
    right:0px;
    background-color:var(--main);
    border:none;
    transform:scale(70%);
    cursor:pointer;
}

.game-start {
    border: 4px solid var(--contrast);
    background-color: var(--main);
    font-size: 48px;
    font-weight:bold;
    padding:10px;
    background: linear-gradient(90deg,var(--contrast) 0%,var(--contrast) 0%, var(--white) 100%);
    -webkit-background-clip: text;
    background-clip:text;
    -webkit-text-fill-color: transparent;
    position:relative;
    cursor:pointer;
    transition:all .3s ease;
}

.game-start:hover {
    transform:scale(1.1);
}

.game-start::before {
    content:"START GAME";
    position:absolute;
    background: linear-gradient(90deg,var(--contrast), var(--white));
    -webkit-background-clip: text;
    background-clip:text;
    -webkit-text-fill-color: transparent;
    top:14px;
    left:14px;
    opacity:50%;
}

.game-highscore-container {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--alt2);
    color:var(--alt1);
    width:100%;
    border:4px solid var(--alt2);
    margin-bottom:10px;
    margin-top:-10px;
    gap:8px;
    padding: 12px 0px;
    text-align: center;
}

.game-highscore-container > p {
    margin:0px;
}

.game-highscore-container >p:first-child {
    font-size:20px;
}

.game-finished-container {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-wrapper {
    position: absolute;
    width: 3dvw;
    top: 5.5%;
    right: -4dvw;
    filter: invert(90%) sepia(6%) saturate(1382%) hue-rotate(179deg) brightness(96%) contrast(91%);
}

.info-wrapper:hover {
    cursor: pointer;
}

.modal-wrapper {
    background-color: var(--main);
    border: 2px solid var(--contrast);
    width: 14dvw;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.close-button {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);

}

.close-button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .game-container {
        width:80%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:60px;
    }

    .game-header {
        font-size:16px;
        height:30px;
    }

    .game-start {
        font-size:36px;
    }

    .game-background {
        position:absolute;
        top:0px;
        height:100%;
        /* visibility: hidden; */
        max-width:100%;
        opacity:10%;
        z-index:-1;
        filter:brightness(75%) sepia(10) saturate(30) hue-rotate(200deg);
    }

    .info-wrapper {
        width: 6dvw;
        right: -8vw;
        top: 65vh;
    }

    .modal-wrapper {
        width: 60dvw;
    }

  }