@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

html {
  overflow-x: hidden;
}

:root {
  color-scheme: only light;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --main: #042d62;
  --alt1: #0b0d3d;
  --alt2: #c1d8eb;
  --contrast: #f08456;
  --soft-contrast: #f0c8b8;
  --white: #fefefa;
  background-color: var(--main);
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  /* position:relative; */
  height:100%;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  position:relative;
  padding:0px;
  min-height:100vh;
}