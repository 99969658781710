.read-more-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.read-more-content-container.expanded {
  height: auto;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.read-more-text-container {
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  text-align: justify;
  color: var(--white);
  transition: opacity 0.5s ease-out;
}

.read-more-button-container {
  margin-top: 10%;
  width: 150px;
  height: 40px;
  border-radius: 9px;
  background-color: var(--contrast);
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  transition: opacity 0.5s ease-out;
}

/* setups for mobile */
@media only screen and (max-width: 768px) {
  .read-more-text-container {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
  }

  .read-more-button-container {
    width: 112px;
    height: 29px;
    font-size: 15px;
  }
}

/* little setups for when mobile is turned horizontal */
@media only screen and (max-width: 900px) and (min-width: 650px) {
  .read-more-content-container {
    margin-top: 10%;
  }
  .read-more-text-container {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }
}
