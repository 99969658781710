.navbar-desktop {
    background-color: rgba(11, 13, 61, 0.75);
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 15px 10px;
    position:sticky;
    top:0px;
    width:100%;
    z-index:100;
    transition: all .3s ease;
    height:70px;
}

.navbar-desktop:hover {
  background-color:rgba(11,13,61,1);
}

.navbar-desktop > a {
  display:flex;
  justify-content: center;
  align-items: center;
}

.navbar-desktop > a > img {
    max-height:50px;
    margin-left:20px;
}

.navbar-desktop-list {
    display:flex;
    justify-content: flex-end;
    width:100%;
    gap:30px;
    margin-right:40px;
    align-items: center;
}

.navbar-desktop-list > li {
    list-style: none;
    position:relative;
}

.navbar-desktop-list > li > a {

    color:var(--white);
    text-decoration:none;
    font-size: 16px;
    /* font-weight:bold; */
}

.navbar-desktop-selected > a {
    color: var(--contrast) !important;
}

.navbar-desktop-li {
  --c: var(--contrast); /* the color */
  --b: 0em;    /* border length*/
  --d: 5px;    /* the cube depth */
  padding:5px;
  --_s: calc(var(--d) + var(--b));
  color: var(--c);
  border: solid #0000;
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background:
    conic-gradient(at left var(--d)  bottom var(--d),
      #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
    conic-gradient(at left var(--_s) bottom var(--_s),
      #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
  transform: translate(calc(var(--d)/-1),var(--d));
  clip-path: 
    polygon(
      calc(var(--d) + 5px) 5%, 
      calc(var(--d) + 5px) 5%, 
      100% 5%, 
      100% calc(100% - var(--d)),
      100% calc(100% - var(--d)),
      calc(var(--d) + 5px) calc(100% - var(--d))
    );
  transition: 0.5s;
}
.navbar-desktop-li:hover {
  transform: translate(0,0);
  clip-path: 
    polygon(
      0% var(--d), 
      var(--d) 0%, 
      100% 0%, 
      100% calc(100% - var(--d)), 
      calc(100% - var(--d)) 100%, 
      0% 100%
    );
    --b:.1em;
}

.langChangerContainer {
  display:flex;
  justify-content: center;
  flex-direction: column;
  /* padding:20px; */
  margin-right:30px;
  gap:5px;
}

.langChangerContainer > button {
  font-size:12px;
  color:var(--white);
  background-color: inherit;
  border:0px solid var(--contrast);
  border-radius:10px;
  cursor:pointer;
  transition: all .3s ease;
  font-family: "Montserrat", sans-serif;
}

.langChangerContainer > .selected {
  color:var(--contrast);
  font-size:16px;
  border:2px solid var(--contrast);
  border-radius:10px;
}

.langChangerContainer > button:hover {
  transform:scale(1.1);
}

/* MOBILE FROM HERE!!! */

.navbar-mobile {
  width:100vw;
  position: sticky;
  top:0px;
  z-index: 10;
}

.invisible {
    z-index: -10 !important;
    display: none !important;
}

.logo-nav-mobile {
  margin-top: 5px;

}

.navbar-mob-first {
  max-width:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:5px 20px;
  background-color: rgba(11, 13, 61,0.75);
  transition: all .3s ease;
}

.navbar-mob-first-selected {
  background-color:rgba(11,13,61,1)
}

.navbar-mob-first > a > img {
  max-width:80px;
}

.navbar-burger {
  --s: 25px; /* control the size */
  --c: var(--white); /* the color */
  
  height: var(--s);
  aspect-ratio: 1;
  border: none;
  padding: 0;
  border-inline: calc(var(--s)/2) solid #0000; 
  box-sizing: content-box;
  --_g1: linear-gradient(var(--c) 20%,#0000 0 80%,var(--c) 0) 
         no-repeat content-box border-box;
  --_g2: radial-gradient(circle closest-side at 50% 12.5%,var(--c) 95%,#0000) 
         repeat-y content-box border-box;
  background: 
    var(--_g2) left  var(--_p,0px) top,
    var(--_g1) left  calc(var(--s)/10 + var(--_p,0px)) top,
    var(--_g2) right var(--_p,0px) top,
    var(--_g1) right calc(var(--s)/10 + var(--_p,0px)) top;
  background-size: 
    20% 80%,
    40% 100%;
  position: relative;
  clip-path: inset(0 25%);
  -webkit-mask: linear-gradient(90deg,#0000,#000 25% 75%,#0000);
  cursor: pointer;
  transition: 
    background-position .3s var(--_s,.3s), 
    clip-path 0s var(--_s,.6s);
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
}
.navbar-burger:before,
.navbar-burger:after {
  content:"";
  position: absolute;
  border-radius: var(--s);
  inset: 40% 0;
  background: var(--c);
  transition: transform .3s calc(.3s - var(--_s,.3s));
}

.navbar-burger:checked {
  clip-path: inset(0);
  --_p: calc(-1*var(--s));
  --_s: 0s;
}
.navbar-burger:checked:before {
  transform: rotate(45deg);
}
.navbar-burger:checked:after {
  transform: rotate(-45deg);
}
.navbar-burger:focus-visible {
  clip-path: none;
  -webkit-mask: none;
  border: none;
  outline: 2px solid var(--c);
  outline-offset: 5px;
}

.navbar-mob-ul {
  position: absolute;
  width: 100dvw;
  display:flex;
  flex-direction: column;
  gap:0px;
  padding:0px;
  margin:0px;
  z-index: 10;
}

.navbar-mob-ul > li {
  list-style: none;
  text-decoration: none;
  font-size:16px;
  font-weight:bold;
  padding:5px 0px;
  background-color: rgba(11, 13, 61,1);
  padding: 5px 0px 5px 20px;
  visibility: hidden;
  opacity:0;
  transition:all .3s ease;
  transform:translateY(-20px);
}

.navbar-mob-vis > li {
  visibility:visible;
  opacity:100;
  transform:translateY(0px);
}

.navbar-mob-selected {
  color:var(--contrast);
}

.lang-changer-mob-container {
  display:flex;
  width:100%;
  justify-content: space-evenly;
  padding-left:0px !important;
}

.lang-changer-mob-container > button {
  font-size:12px;
  color:var(--white);
  background-color: rgba(11, 13, 61,0);
  border:0px solid var(--contrast);
  border-radius:10px;
  cursor:pointer;
  transition: all .3s ease;
  font-family: "Montserrat", sans-serif;
}

.lang-changer-mob-container > .selected {
  color:var(--contrast);
  font-size:16px;
  border:2px solid var(--contrast);
  border-radius:10px;
}
