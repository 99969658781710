.faq-title-wrapper-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.faq-lines-container-left-mobile {
    display: flex;
    width: 100%;
    height: fit-content;
}

.faq-line-design-left-mobile {
    width: 60vw;
    mix-blend-mode: color-dodge;
}

.faq-lines-container-right-mobile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: -15vw
}

.faq-line-design-right-mobile {
    width: 60vw;
    mix-blend-mode: color-dodge;
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    z-index: -1;
}

.faq-title-container-mobile {
    display: flex;
    justify-content: center;
    margin-top: -15vw;
}

.faq-top-section-text-mobile {
    text-align: justify;
    width: 80vw;
    color: var(--white);
    font-size: 3.6vw;
    padding-bottom: 2px;
    font-weight: 400;
    font-family: Montserrat;
    border-bottom: 2px solid var(--white);
}


.faq-top-section-mobile {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.faq-line-arrow-mobile {
    height: 10vw;
}

.faq-rules-button-mobile, .faq-contact-button-mobile, .faq-rules-button, .faq-contact-button {
    width: 80vw;
    border: 2px solid var(--alt2);
    background: none;
    padding: 3vw 0px;
    color: var(--white);
    text-align: center;
    font-family: Montserrat;
    font-size: 5vw;
    position: relative;
}

.rules-button-icon-wrapper-mobile, .contact-button-icon-wrapper-mobile, .rules-button-icon-wrapper, .contact-button-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 7vw;
    height: 7vw;
    right: 2vw;
    top: 50%;
    transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    background-color: var(--alt2);
}

.faq-contact-button-mobile, .faq-contact-button {
    background-color: var(--alt2);
    color: var(--main);
}

.contact-button-icon-wrapper-mobile, .contact-button-icon-wrapper {
    background-color: var(--main);
}

.rules-button-icon-mobile, .contact-button-icon-mobile, .rules-button-icon, .contact-button-icon {
    height: 80%;
    transform: translate(-20%, 0);
    -moz-transform: translate(-20%, 0);
    -webkit-transform: translate(-20%, 0);
    -o-transform: translate(-20%, 0);
    -ms-transform: translate(-20%, 0);
}

.faq-lines-FAQ-mobile {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);

}

.faq-main-section-mobile {
    background-image: url('../../svg/faq-bg.svg');
    background-repeat: repeat;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 43px;
}

.faq-bottom-section-mobile {
    width: 100vw;
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
    flex-direction: column;
    margin-top: 36px;
}

.rules-back-mobile {
    position: absolute;
    top: 0px;
    left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--alt2);
}

.rules-back-icon-mobile {
    height: 80%;
    transform: translate(20%, 0) scale(-1, 1);
    -moz-transform: translate(20%, 0) scale(-1, 1);
    -webkit-transform: translate(20%, 0) scale(-1, 1);
    -o-transform: translate(20%, 0) scale(-1, 1);
    -ms-transform: translate(20%, 0) scale(-1, 1);
}

.rules-section-mobile {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 50px
}

.rules-section {
    color: var(--white);
    font-family: Montserrat;
    font-weight: 700;
}

.section-wrapper {
    margin-bottom: 22px;
}

.rules-title {
    color: var(--white);
    font-family: Montserrat;
    font-weight: 700;
}

.rules-container-mobile {
    width: 75vw;
    height: 400px;
    overflow: scroll;
}

.rules-description {
    color: var(--white);
    text-align: justify;
    font-family: Montserrat;
    font-weight: 400;
}

.faq-top-section {
    width: 80dvw;
    display: flex;
    margin-top: 4dvw;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4dvh;
}

.faq-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-title-wrapper {
    display: flex;
    flex-direction: column;
}

.faq-description {
    width: 45dvw;
    color: var(--white);
    text-align: justify;
    font-family: Montserrat;
    font-size: 1.2dvw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq-buttons-section {
    width: 80dvw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.faq-contact-button, .faq-rules-button {
    width: 39dvw;
    padding: 1dvw 0px;
    font-size: 2.6dvw;
}

.faq-contact-button:hover, .faq-rules-button:hover {
    cursor: pointer;
}

.contact-button-icon-wrapper, .rules-button-icon-wrapper {
    width: 3.5dvw;
    height: 3.5dvw;
    right: 2%;
}

.faq-main-section {
    width: 80dvw;
    display: flex;
    gap: 1.8dvw;
    flex-wrap: wrap;
    justify-content: space-between;
}

.faq-modal {
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.rules-button-wrapper, .rules-wrapper {
    width: 90dvw;
}

.close-modal-button {
    margin-top: 11dvh;
    background-color: var(--main);
    border: 2px solid var(--contrast);
    height: 3.5dvw;
    width: 3.5dvw;
    margin-left: -30px;
}

.close-modal-button:hover {
    cursor: pointer;
}

.rules-back-icon {
    height: 70%;
}

.rules-wrapper {
    height: 70dvh;
    background-color: var(--main);
    border: 2px solid var(--contrast);
    padding: 15px 30px;
    overflow-y: scroll;
    overflow-x: hidden;
}   