.contact-form {
    all: unset;
    display: flex;
    width: 720px;
    min-height: 430px;
    padding: 12px 10px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border: 2px solid var(--contrast, #F08456);
    background: var(--main, #042D62);
}

.contact-form-title {
    display: none;
}


.contact-form-names-container {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.contact-form-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    
}

.contact-form-name-label {
    color: var(--white, #FEFEFA);
    font-family: Montserrat;
    font-size: 20px;
    margin-bottom:4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-form-name-field {
    all: unset;
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: var(--alt2, #C1D8EB);

}



.contact-form-question-container {
    display: flex;
    height: 247px;
    margin-top: 10px;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    align-self: stretch;
}

.contact-form-question-field {
    all: unset;
    display: flex;
    height: 210px;
    padding: 10px 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--alt2, #C1D8EB);
}

.contact-form-field-text {
    color: var(--main, #042D62);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-form-footer-container {
    display: flex;
    padding: 12px 0px;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.contact-form-success {
    color: #33cc00;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.contact-form-error {
    color: #ee1010;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-form-button {
    all: unset;
    display: flex;
    height: 54px;
    padding: 10px 30px;
    border: solid 2px var(--contrast);
    margin-top:10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--alt1, #0B0D3D);
    color: var(--white, #FEFEFA);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor:pointer;
}

.contact-form-button {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .contact-form {
        all: unset;
        display: flex;
        width: 284px;
        padding: 12px 10px;
        flex-direction: column;
        align-items: center;
        background: var(--alt2, #C1D8EB);
    }

    .contact-form-title {
        display: block;
        color: var(--main, #042D62);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .contact-form-names-container {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
    }

    .contact-form-name-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
    }

    .contact-form-name-label {
        color: var(--main, #042D62);
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .contact-form-name-field {
        all: unset;
        display: flex;
        padding: 4px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background: var(--main, #042D62);
    }

    .contact-form-question-container {
        all: unset;
        display: flex;
        padding: 0px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }
    .contact-form-question-field {
        display: flex;
        height: 147px;
        padding: 10px 13px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background: var(--main, #042D62);
    }

    .contact-form-field-text {
        color: var(--white, #FEFEFA);
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .contact-form-footer-container {
        all: unset;
        display: flex;
        padding: 12px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .contact-form-button {
        all: unset;
        display: flex;
        height: 30px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: var(--alt1, #0B0D3D);
        color: var(--white, #FEFEFA);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contact-form-error {
        font-size: 10px;
    }

    .contact-form-success {
        font-size: 10px;
    }

}


.contact-form-error-field {
    border: 2px solid var(--contrast, #F08456);
}