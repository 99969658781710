.contact-top-level-page {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

.contact-header {
    display: flex;
    justify-content: center;
    margin: 0px;
    margin-bottom:50px;
    @media screen and (max-width: 768px)  {
        margin-top: 20px;
    }
}

.contact-lines-top {
    width: 26vw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(-90deg);
    top: -12vw;
    right: -1vw;
    z-index: -1;
    @media screen and (max-width: 768px)  {
        display: none;
    }
}

.contact-lines-bot {
    width:26vw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(90deg);
    top: 15%;
    left: -6vw;
    z-index: -1;
    @media screen and (max-width: 768px)  {
        display: none;
    }
}

.contact-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}