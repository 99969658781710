.about-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
}

.header-about-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0;
  margin-top: -5%;
}

.header-about-design-wrapper {
  width: 50%;
}

.header-design-about-container {
  position: absolute;
  width: 50%;
  right: 0;
  mix-blend-mode: color-dodge;
  z-index: -2;
}

.header-about-name-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5vw;
  margin-right: 20px;
  margin-left: 5vw;

  background: linear-gradient(90deg, var(--white) 0%, var(--alt2) 100%);
  color: transparent;
  background-clip: text;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 4px 4px;
  font-size: 24px;
  font-weight: 700;
  z-index: -1;
}

.title-logo-img {
  position: relative;
  z-index: -1;
}

.header-about-title-logo-wrapper {
  width: 20%;
  margin-left: -20%;
}

.about-content-container {
  width: 50%;
  margin-left: 5vw;
  margin-top: 15px;
}

.img-container {
  position: relative;
  top: 50px;
}

.line-design-img {
  width: 100%;
}

/*
Overlapping images

we have:
- 1st layer -> blue rectangle 
- 2nd layer -> photo
- 3rd layer -> eo12 frame 
*/
.overlap-group-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eo-frame-img,
.photo-img,
.rectangle-background {
  width: 300px;
  height: 167px;
  top: 0;
  gap: 0px;
}

.eo-frame-img {
  position: absolute;
  opacity: 1;
}

.photo-img {
  position: absolute;
  opacity: 0.3;
  z-index: -1;
}

.rectangle-background {
  opacity: 0.7;
  background-color: var(--alt1);
  z-index: -2;
}

/* little setups for when mobile is turned horizontal */
@media only screen and (max-width: 900px) and (min-width: 650px) {
  .header-about-name-container {
    margin-left: 0;
  }
  .header-design-about-container {
    width: 40%;
  }
  .header-about-title-logo-wrapper {
    margin-left: -10%;
  }
  .reverse .header-about-name-container {
    justify-content: flex-end;
  }
  .eo-frame-img,
  .photo-img,
  .rectangle-background {
    width: 400px;
    height: 220px;
  }
}

/* Reverse elements to make left side component */
.reverse .header-about-container {
  flex-direction: row-reverse;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse .title-logo-img {
  transform: rotateY(180deg);
}

.reverse .header-about-name-container {
  justify-content: flex-end;
  margin-left: 17vw
}

.reverse .header-about-title-logo-wrapper {
  margin-right: 7%;
  margin-top: 0;
}

.reverse .header-design-about-container {
  left: 0;
  transform: rotateY(180deg);
}

.reverse .about-content-container {
  margin-left: 45%;
}
