
.profile-component {
    display: flex;
    padding: 15px 19px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}


.profile-heptagon-container {
    position: relative;
    width: calc(148px + 4vw);
}

.profile-full-description {
    display: flex;
    padding: 9px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: max(0px, calc(0.8vw - 7.14px));
}

.profile-team {
    flex-direction: column-reverse;
}

.profile-text {
    color: var(--white, #FEFEFA);
    font-family: Montserrat;
    font-size: calc(25px + 0.3vw);
    font-style: normal;
    line-height: normal;
    
    @media screen and (max-width: 768px) {
        font-size: calc(20px + 0.3vw);
    }
}

.profile-name {
    font-size: calc(25px + 0.3vw);
}

.profile-bold {
    font-weight: 700;
}

.profile-heptrail-left {
    display: none;
}

.profile-heptrail-right {
    display: none;
}

@media screen and (max-width: 768px) {
    .profile-component {
        flex-direction: column-reverse;
        gap: 0px;
    }
    .profile-no-mobile {
        display: none;
    }

    .profile-heptrail-left {
        display: block;
        position: absolute;
        top: -30px;
        left: -100px;
        z-index: -1;
    }

    .profile-heptrail-right {
        display: block;
        position: absolute;
        top: -30px;
        left: 80px;
        z-index: -1;
        transform: rotate(180deg);
    }
    
}
