.about-page-container {
  margin-top: 20px;
}

.team-wrapper-mobile {
  margin-top: 100px;
}

.team-wrapper {
  margin-bottom: 100px;
}