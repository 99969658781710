footer {
    background-image: linear-gradient(0deg,rgba(11, 13, 61,100),rgba(11, 13, 61,0) );
    /* color:var(--alt1) */
    position:absolute;
    width:100vw;
    bottom:-20px;
    left:0px;
    padding:20px 0px;
    margin:0px;
}

footer > ul {
    display:flex;
    justify-content: center;
    gap:16px;
    padding-left:0px;
}

.footer-line {
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:20px;
    gap:4px;
    padding:2px;
}

@media only screen and (max-width: 768px) {
    .footer-line {
        display:flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
    }
  }