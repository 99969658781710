.main-view-wrapper {
    height: 130vh;
    position: relative;
    overflow:hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo-container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 74px;
}

.logo-main {
    width: 20dvw;
    margin-top: 19dvh
}

.line-design-main-top-left {
    width: 8.5dvw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(110deg) scale(4.5);
    top: -18%;
    left: -20px;
    z-index: -1;
}

.line-design-main-middle-left {
    width: 8.5dvw;
    mix-blend-mode: color-dodge;
    position: absolute;
    left: -60px;
    top: 23%;
    transform: rotate(90deg) scale(4.5);
    -moz-transform: rotate(90deg) scale(4.5);
    -webkit-transform: rotate(90deg) scale(4.5);
    -o-transform: rotate(90deg) scale(4.5);
    -ms-transform: rotate(90deg) scale(4.5);
    z-index: -1;

}

.line-design-main-bottom-left {
    width: 8.5dvw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(60deg) scale(4.5);
    -moz-transform: rotate(60deg) scale(4.5);
    -webkit-transform: rotate(60deg) scale(4.5);
    -o-transform: rotate(60deg) scale(4,5);
    -ms-transform: rotate(60deg) scale(4.5);
    top: 70%;
    left: -50px;
    z-index: -1;

}

.line-design-main-top-right {
    width: 8.5dvw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(70deg) scale(4.5, -4.5);
    top: -18%;
    right: -20px;
    z-index: -1;
    -moz-transform: rotate(70deg) scale(4.5, -4.5);
    -webkit-transform: rotate(70deg) scale(4.5, -4.5);
    -o-transform: rotate(70deg) scale(4.5, -4.5);
    -ms-transform: rotate(70deg) scale(4.5, -4.5);
    transform: rotate(70deg) scale(4.5, -4.5);

}

.line-design-main-middle-right {
    width: 8.5dvw;
    mix-blend-mode: color-dodge;
    position: absolute;
    right: -60px;
    top: 23%;
    transform: rotate(90deg) scale(4.5, -4.5);
    -moz-transform: rotate(90deg) scale(4.5, -4.5);
    -webkit-transform: rotate(90deg) scale(4.5, -4.5);
    -o-transform: rotate(90deg) scale(4.5, -4.5);
    -ms-transform: rotate(90deg) scale(4.5, -4.5);
    z-index: -1;
}

.line-design-main-bottom-right {
    width: 8.5dvw;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(120deg) scale(4.5, -4.5);
    -moz-transform: rotate(120deg) scale(4.5, -4.5);
    -webkit-transform: rotate(120deg) scale(4.5, -4.5);
    -o-transform: rotate(120deg) scale(4,5, -4.5);
    -ms-transform: rotate(120deg) scale(4.5, -4.5);
    top: 70%;
    right: -50px;
    z-index: -1;
}

.apply-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.prize-section, .dates-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.prize-subtitle {
    font-family: Montserrat;
    width: 100%;
    font-size: 3.4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 160px;
    margin-bottom: 85px;
    text-align: center;
}

.prize-subtitle-less-margin {
    margin-top: 0px !important;
}

.prizes-wrapper, .dates-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 160px;
}

.dates-wrapper {
    margin-top: 100px;
}

.category-devider {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
}

.timer-wrapper {
    margin-top: 83px;
    margin-bottom: 680px;
}

/* ########## */

.main-view-wrapper-mobile {
    height: 100vh;
    position: relative;
    overflow:hidden;
    min-height: 750px;
}

.logo-container-mobile {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 53px;
}

.logo-main-mobile {
    width: 55vw;
    margin-top: 19vh
}

.line-design-main-left-mobile {
    width: 180px;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(135deg) scale(1.3);
    top: -130px;
    left: -100px;
    z-index: -1;
}

.line-design-main-center-mobile {
    width: 180px;
    mix-blend-mode: color-dodge;
    position: absolute;
    left: 50%;
    top: -180px;
    transform: translate(-50%, 0) rotate(180deg) scale(1.3);
    -moz-transform: translate(-50%, 0) rotate(180deg) scale(1.3);
    -webkit-transform: translate(-50%, 0) rotate(180deg) scale(1.3);
    -o-transform: translate(-50%, 0) rotate(180deg) scale(1.3);
    -ms-transform: translate(-50%, 0) rotate(180deg) scale(1.3);
    z-index: -1;

}

.line-design-main-right-mobile {
    width: 180px;
    mix-blend-mode: color-dodge;
    position: absolute;
    transform: rotate(45deg) scale(1.3, -1.3);
    -moz-transform: rotate(45deg) scale(1.3, -1.3);
    -webkit-transform: rotate(45deg) scale(1.3, -1.3);
    -o-transform: rotate(45deg) scale(1.3, -1.3);
    -ms-transform: rotate(45deg) scale(1.3, -1.3);
    top: -130px;
    right: -100px;
    z-index: -1;

}

.line-design-main-bottom-left-mobile {
    width: 220px;
    mix-blend-mode: color-dodge;
    position: absolute;
    bottom: -20%;
    left: -80px;
    transform: translate(0, -50%) rotate(25deg) scale(1.15);
    -moz-transform: translate(0, -50%) rotate(25deg) scale(1.15);
    -webkit-transform: translate(0, -50%) rotate(25deg) scale(1.15);
    -o-transform: translate(0, -50%) rotate(25deg) scale(1.15);
    -ms-transform: translate(0, -50%) rotate(25deg) scale(1.15);
    z-index: -1;

}

.line-design-main-bottom-center-mobile {
    width: 220px;
    mix-blend-mode: color-dodge;
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.15);
    -moz-transform: translate(-50%, -50%) scale(1.15);
    -webkit-transform: translate(-50%, -50%) scale(1.15);
    -o-transform: translate(-50%, -50%) scale(1.15);
    -ms-transform: translate(-50%, -50%) scale(1.15);
    z-index: -1;
}

.line-design-main-bottom-right-mobile {
    width: 220px;
    mix-blend-mode: color-dodge;
    position: absolute;
    bottom: -20%;
    right: -80px;
    transform: translate(0, -50%) rotate(155deg) scale(1.15, -1.15);
    -moz-transform: translate(0, -50%) rotate(155deg) scale(1.15, -1.15);
    -webkit-transform: translate(0, -50%) rotate(155deg) scale(1.15, -1.15);
    -o-transform: translate(0, -50%) rotate(155deg) scale(1.15, -1.15);
    -ms-transform: translate(0, -50%) rotate(155deg) scale(1.15, -1.15);
    z-index: -1;
}

.apply-wrapper-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.prize-section-mobile, .dates-section-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.prize-subtitle-mobile {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 5px;
    margin-top: 11px;
}

.category-devider-mobile {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
}