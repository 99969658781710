.orange-gradient-button {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 4px solid var(--Contrast, #F08456);
}

.button-wrapper {
    position: relative;
    z-index: 10;
}

.button-wrapper:hover {
    cursor: pointer;
}

.button-background {
    position: absolute;
    top: 3px;
    right: -3px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 4px solid var(--Contrast, #F08456);
    opacity: 0.6;
    z-index: -1;
}